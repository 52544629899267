/* eslint-disable no-console,no-undef,camelcase */
import React, { Component } from "react";
import InputTrigger from "react-input-trigger";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Image from '../Image';
import _ from 'lodash';
import { UserDropdownContainer, ProfilePicture, ListDropdown, UserContainer, DropDownStyledImage,
  ShowTaggedUser, ShoutImage, Cross, ShoutoutDiv, SelectedImage, ProfileImg, ShowTaggedUser2, InputTextAreaNew } from "./styles"; //  ShoutoutContainer, IconContainer,
import { StyledModal, StyledBody, MainContent, PeerButton } from '../RecognitionList/styles';
import { getUsersToShoutout, getCompanyExemplifiesValues } from '../../redux/actions';
import { imgPath, ImageUrl } from '../../utils/constants';
import SocialFeedsEmojiPicker from "../SocialFeedsEmojiPicker";
const iSiOS = !!window.navigator.platform && /iPad|iPhone|iPod/.test(window.navigator.platform);

class SocialFeedTextarea extends Component {
  constructor() {
    super();
    this.state = {
      top: null,
      left: null,
      showSuggestor: false,
      startPosition: null,
      text: null,
      currentSelection: 0,
      employee: [],
      thankYouShoutout: null,
      congratsShoutout: null,
      jobWellDoneShoutout: null,
      textareaValue: '',
      textLength: null,
      trigger: {},
      taggedUser: '',
      showModal: false,
      emojiId: null,
      emojiNative: null,
      emojiSkin: null,
      emojiData: null
    };

    this.backdropRef = React.createRef();

    this.toggleSuggestor = this.toggleSuggestor.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleKey = this.handleKey.bind(this);
  }

  componentDidMount() {
    const {  employeeData} = this.props;
    if(_.isEmpty(employeeData) && employeeData && employeeData.length === 0)this.fetchUsers();
    this.checkDevice();
    // if(_.isEmpty(exemplifiesValues) && exemplifiesValues && exemplifiesValues.length === 0)getCompanyExemplifiesValues();
  }

  checkDevice = () => {
    let isAndroid = window.navigator.userAgent.toLowerCase().indexOf("android") > -1;
    if(isAndroid) {
      let triggerObj = {};
      triggerObj.keyCode = 229;
      this.setState({
        trigger: triggerObj
      });
    } else {
      let triggerObj = {};
      triggerObj.keyCode = 50;
      triggerObj.shiftKey = !iSiOS;
      this.setState({
        trigger: triggerObj
      })
    }
  };

  toggleSuggestor(metaInformation) {
    const { hookType, cursor } = metaInformation;
    if (hookType === "start") {
      this.setState({
        showSuggestor: true,
        left: cursor.left,
        top: cursor.top + cursor.height,
        startPosition: iSiOS ? (this.textarea.value.length === 0 ? 1 : this.textarea.value.length) : cursor.selectionStart
      });
    }
    if (hookType === "cancel") {
      this.setState({
        showSuggestor: false,
        left: null,
        top: null,
        text: null,
        startPosition: null
      });
    }
  }

  storeFetchedEmployee = () => {
    this.setState({
      employee: this.props.employeeData
    });
  };

  fetchUsers = () => {
    const { userCompany, fetchUsersToShoutout } = this.props;
    let obj = {};
    obj['company_id'] = userCompany.id;
    obj['name'] = this.state.text;
    obj['limit'] = 6;
    if(!_.isNull(userCompany.id))
      fetchUsersToShoutout(obj, this.storeFetchedEmployee);
  };

  handleInput(metaInformation) {
    if(metaInformation.text.includes('@')){
      this.setState({
        text: '',
        textLength: iSiOS ? metaInformation.text.length + 1 : metaInformation.text.length
      },() => this.fetchUsers());
    }
    else{
      this.setState({
        text: metaInformation.text,
        textLength: iSiOS ? metaInformation.text.length + 1 : metaInformation.text.length
      },() => this.fetchUsers());
    }

  }

  handleKey(event) {
    const { which } = event;
    const { currentSelection, employee } = this.state;
    if(_.isNaN(currentSelection)) {
      event.preventDefault();
      this.setState({
        currentSelection: 0
      });
    }

    if (which === 40) {
      event.preventDefault();

      this.setState({
        currentSelection: (currentSelection + 1) % employee.length,
      });
    }

    if (which === 38 ) { 
      event.preventDefault();
      if(currentSelection - 1 < 0)
        this.setState({
          currentSelection: employee.length - 1,
        })
      else 
        this.setState({
          currentSelection: (currentSelection - 1) % employee.length,
        });
    }

    if (which === 13 && this.state.showSuggestor && employee[currentSelection]) {
      event.preventDefault();

      const { employee, currentSelection, textLength, startPosition } = this.state;
      const { inspirationQuote } = this.props;
      const taggedUser = employee[currentSelection].fullname.trim();
      const userId = employee[currentSelection].uid;
      const newText = `${inspirationQuote.slice(0,startPosition - 1)}${inspirationQuote.slice(startPosition + textLength, inspirationQuote.length)}`;
      this.setState({
        showSuggestor: false,
        left: null,
        top: null,
        text: null,
        startPosition: null,
        textareaValue: newText,
        currentSelection: 0,
        taggedUser: taggedUser
      }, () => {
        this.props.updateText(newText, userId, taggedUser, true)
      });

      this.endHandler();
    }
  }

  handleClick = () => {
    const { employee, currentSelection, textLength, startPosition } = this.state;
    const { inspirationQuote } = this.props;
    let userArray = [...this.props.users];
    const taggedUser = employee[currentSelection].fullname.trim();
    if(userArray.indexOf(taggedUser) === -1)
      userArray.push(taggedUser);
    const userId = employee[currentSelection].uid;
    const newText = `${inspirationQuote.slice(0,startPosition - 1)}${inspirationQuote.slice(startPosition + textLength, inspirationQuote.length)}`;
    this.setState({
      showSuggestor: false,
      left: null,
      top: null,
      text: null,
      startPosition: null,
      textareaValue: newText,
      currentSelection: 0,
      taggedUser: taggedUser
    }, () => {
      this.props.updateText(newText, userId, taggedUser, true);
    });

    this.endHandler();
  }

  handleMouseHover = (index) => {
    this.setState({
      currentSelection: index,
    });
  }

  onScroll = (event) => {
    const scrollTop = event.target.scrollTop;
    if(this.backdropRef && this.backdropRef.current) {
      this.backdropRef.current.scrollTop = scrollTop;
    }
  }

  changeModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal
    }));
  }

  onEmojiSelect = (emoji, id, native, skin, unified) => {
    this.setState({
      emojiData: emoji,
      emojiId: id,
      emojiNative: native,
      emojiSkin: skin
    })
    this.props.addInspiration('', `&#x${unified};`);
  }

  onShoutoutSelect = (text, id, image) => {
    const { userIdArray } = this.props;
    this.props.updateShoutoutArray(userIdArray, text, image, id);
    id >= 1 && this.changeModal();
  };

  removeShoutout = () => {
    const { shoutoutText, onRemoveUser, onRemoveShoutout } = this.props;
    !shoutoutText ? onRemoveUser() : onRemoveShoutout(shoutoutText)
  };

  render() {        
    const { top, left, showSuggestor, employee, currentSelection, trigger } = this.state; // showModal
    const { placeholder, isCommentOrReply, shoutoutText,
      shoutoutImage, taggedUser, enableTrigger, ShowEmojiContainer, exemplifiesValues, displayEmojiPicker, selectedImage, wishesAndAnni, className, popup} = this.props; //  , userIdArray

    return (
      <div>
        <ShoutoutDiv onClick={() => popup()}>
          {
            wishesAndAnni && this.props.shoutOutData && this.props.shoutOutData.name && this.props.shoutOutData.name !== "" ? (
              <div>
                <ShowTaggedUser2>
                  <ProfileImg margin={"0px 5px 0 0px"}>
                    <img src={`https://s3.amazonaws.com/images.trainingamigo.com/${this.props.shoutOutData.profile_image}`} />
                  </ProfileImg>
                  {`${this.props.shoutOutData.name ? this.props.shoutOutData.name : ""}`}
                  <Cross src={`${imgPath}/wrong-tic.png`} onClick={this.removeShoutout} />
                </ShowTaggedUser2>
              </div>
            ) : null
          }
          { this.props.showGivenShoutout &&
            <div>
              { _.isNull(shoutoutText) || _.isNull(shoutoutImage) ?
                <ShowTaggedUser>
                  {`${taggedUser ? taggedUser : ""}`}
                  <Cross src={`${imgPath}/wrong-tic.png`} onClick={this.removeShoutout} />
                </ShowTaggedUser> :
                <ShowTaggedUser>
                  {/* {`${taggedUser ? taggedUser : ""} - ${shoutoutText}`} */}
                  <ShoutImage src={`${ImageUrl}/${shoutoutImage}`} />
                  {`${taggedUser ? taggedUser : ""} - ${shoutoutText}`}
                  <Cross src={`${imgPath}/wrong-tic.png`} onClick={this.removeShoutout} />
                </ShowTaggedUser>
              }
            </div>
          }
        </ShoutoutDiv>
        {
          selectedImage && selectedImage.length>0 && <SelectedImage>
            <img src={selectedImage} alt="uploadable" />
          </SelectedImage>
        }
        {wishesAndAnni && this.props.shoutOutData && this.props.shoutOutData.name && this.props.shoutOutData.name !== "" ? null : <InputTextAreaNew
          onKeyDown={this.handleKey}
          isCommentOrReply={isCommentOrReply}
          padding={placeholder.includes('reply') ? '13px' : '0'}
          width={placeholder.includes('reply') ? '80%' : '100%'}
        >
          <InputTrigger
            trigger={trigger}
            onStart={(metaData) => { enableTrigger && this.toggleSuggestor(metaData);}}
            onCancel={(metaData) => {enableTrigger && this.toggleSuggestor(metaData);}}
            onType={(metaData) => { enableTrigger && this.handleInput(metaData);}}
            endTrigger={(endHandler) => {this.endHandler = endHandler;}}
          >
            <textarea
              placeholder={placeholder}
              rows="4"
              name=""
              value={''}
              data-gramm_editor="false"
              className={className ? className : 'CustomTextAreaField'}
              onScroll={this.onScroll}
              spellCheck="false"
              ref={(node) => this.textarea = node}
            />
          </InputTrigger>
          <UserContainer
            showSuggestor={ showSuggestor }
            top={ `${top}px` }
            left={ `${left}px` }
            width="350px"
          >
            {employee !== null ? employee.map((user, index) => (
              <UserDropdownContainer
                key={index}
                index={index}
                onMouseEnter={() => this.handleMouseHover(index)}
                currentSelection={currentSelection}
                onClick={this.handleClick}
              >
                <ProfilePicture>
                  <DropDownStyledImage width="100%" left height='30px'>
                    <div className="middle">
                      <Image image={user.profile_image} alt={user.fname} />
                    </div>
                    <ListDropdown
                      index={index}
                      currentSelection={currentSelection}
                    >
                      { user.fullname }
                    </ListDropdown>
                  </DropDownStyledImage>
                </ProfilePicture>
              </UserDropdownContainer>
            )) : null}
          </UserContainer>
        </InputTextAreaNew>
        } 
        {
          ShowEmojiContainer ?
            <StyledModal
              show={ShowEmojiContainer}
              onHide={this.props.closeEmojiContainer}
              ref={this.props.myref}
            >
              <StyledBody>
                <MainContent>
                  {exemplifiesValues ? exemplifiesValues.map((recognition) => (
                    <PeerButton
                      value={recognition.core_value}
                      onClick={() => this.onShoutoutSelect(recognition.core_value, recognition.id, recognition.image)}
                      key={recognition.id}
                    >
                      <img src={`${ImageUrl}/${recognition.image}`} height={recognition.image.includes('passionate.png') ? '30px' : '20px'}/>
                      <div>{recognition.core_value}</div>
                    </PeerButton>
                  ))
                    : null
                  }
                </MainContent>
              </StyledBody>
            </StyledModal> : null
        }
        {displayEmojiPicker ? <SocialFeedsEmojiPicker selectEmoji={this.onEmojiSelect} emojiRef={this.props.emojiRef}/> : null}
      </div>
    );
  }
}

SocialFeedTextarea.propTypes = {
  employeeData: PropTypes.array,
  fetchUsersToShoutout: PropTypes.func,
  addInspiration: PropTypes.func,
  userCompany: PropTypes.object.isRequired,
  inspirationQuote: PropTypes.string,
  className: PropTypes.string,
  updateText: PropTypes.func,
  users: PropTypes.array,
  userIdArray: PropTypes.array,
  placeholder: PropTypes.string,
  updateShoutoutArray: PropTypes.func,
  showGivenShoutout: PropTypes.bool,
  onRemoveShoutout: PropTypes.func,
  isCommentOrReply: PropTypes.string,
  shoutoutImage: PropTypes.string,
  shoutoutText: PropTypes.string,
  taggedUser: PropTypes.string,
  onRemoveUser: PropTypes.func,
  enableTrigger: PropTypes.bool,
  exemplifiesValues: PropTypes.array,
  getCompanyExemplifiesValues: PropTypes.func,
  ShowEmojiContainer: PropTypes.bool,
  myref: PropTypes.object,
  displayEmojiPicker: PropTypes.bool,
  showEmojiPicker: PropTypes.func,
  selectedImage : PropTypes.array,
  shoutOutData: PropTypes.obj,
  wishesAndAnni: PropTypes.number,
  closeEmojiContainer: PropTypes.func,
  emojiRef: PropTypes.object,
  popup: PropTypes.func,
};

const mapStateToProps = (state) => ({
  employeeData: state.peopleHome.employeeData,
  exemplifiesValues: state.social.exemplifiesValues
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsersToShoutout: (data, cb) => dispatch(getUsersToShoutout(data, cb)),
  getCompanyExemplifiesValues: () => dispatch(getCompanyExemplifiesValues())
});

export default connect(mapStateToProps, mapDispatchToProps)(SocialFeedTextarea);
